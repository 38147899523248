
// Services
import ApiService from './api'

// Initialise new instance of the API service
const apiService = new ApiService()

export default class EventService {
	async get(id = null) {
		if(!id) {
			return await apiService.get('events')
		}

		return await apiService.get(`events/${id}`)
	}

	async post(formData = {}) {
		return await apiService.post(`events`, {body: formData}, true)
	}

	async put(eventId, formData = {}) {
		return await apiService.put(`events/${eventId}`, {body: formData}, true)
	}

	async getIds() {
		return await apiService.get(`events/ids`)
	}

	async toggleFeatured(id, featured) {
		const requestOptions = {
			body: JSON.stringify({
				featured: featured ? 0 : 1,
			}),
		}
		return await apiService.put(`events/${id}`, requestOptions)
	}

	formData(inputValues) {
		const formData = new FormData()

		if (inputValues['event']['publish_at_date']) {
			inputValues['event']['publish_at'] = `${inputValues['event']['publish_at_date']} ${inputValues['event']['publish_at_time']}`
		}
		if (inputValues['event']['starts_date']) {
			inputValues['event']['starts'] = `${inputValues['event']['starts_date']} ${inputValues['event']['starts_time']}`
			inputValues['event']['ends'] = inputValues['event']['starts']
			inputValues['event']['door_times'] = `${inputValues['event']['starts_date']} ${inputValues['event']['door_times_time']}`
		}
		if (inputValues['event']['geo_locked']['value'] !== 1) {
			inputValues['event']['geo_lock_radius'] = -1
		}
		if (inputValues['event']['tags'] && inputValues['event']['tags'].length) {
			const tagString = inputValues['event']['tags'].map((tag) => {
				return tag.label
			}).join(',')
			formData.set('tags', tagString)
		}

		Object.keys(inputValues['event']).forEach(key => {
			if (['artist_id', 'type_id', 'venue_id', 'geo_locked'].includes(key)) {
				// deal with react-select object values
				formData.set(key, inputValues['event'][key]['value'])
			}
			else if (key !== 'tags') {
				formData.set(key, inputValues['event'][key])
			}
		})

		return formData
	}
}
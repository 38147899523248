// Libraries
import * as React from 'react'
import moment from 'moment'
import debounce from 'debounce-promise'

// Services
import ApiService from '../../../services/api'
import EventService from '../../../services/event'
import EventTypeService from '../../../services/eventType'
import VenueService from '../../../services/venue'
import TagService from '../../../services/tag'
import ArtistService from '../../../services/artist'

// Components
import PrivateRoute from '../../../components/privateRoute'
import withLocation from '../../../components/withLocation'
import LayoutAdmin from '../../../components/layoutAdmin'
import Seo from '../../../components/seo'
import AdminEventViewRow from '../../../components/admin/events/adminEventViewRow'
import InputFactory from '../../../components/inputFactory'
import Button from '../../../components/button'
import DuoSection from '../../../components/duoSection'

// Context
import NotificationContext from '../../../contexts/notification'

class AdminEventsNewPage extends React.Component {
	state = {
		initialValues: {
			event: {},
		},
		inputValues: {
			event: {
				publish_at_date: moment().format('YYYY-MM-DD'),
				publish_at_time: moment().format('HH:mm'),
				max_tickets_per_transaction: 2,
				geo_locked: {
					label: 'No',
					value: 0
				}
			},
		},
		inputErrors: {
			event: {},
		},
		eventTypeOptions: [],
		venueOptions: [],
		isSubmitting: false
	}

	static contextType = NotificationContext

	constructor(props) {
		super(props);

		const wait = 1000; // milliseconds
		const loadTagOptions = (inputValue, type) => this.loadTagOptions(inputValue, type);
		const loadArtistOptions = (inputValue) => this.loadArtistOptions(inputValue);

		this.debouncedLoadTagOptions = debounce(loadTagOptions, wait, {
			leading: true
		});

		this.debouncedLoadArtistOptions = debounce(loadArtistOptions, wait, {
			leading: true
		});
	}

	render() {
		const {inputValues, initialValues, inputErrors, eventTypeOptions, venueOptions, isSubmitting} = this.state

		return <PrivateRoute admin>
			<LayoutAdmin className="page--admin-form nav-blue-half" hideSideNav pageAdminForm>
				<Seo title="New Event" />
				<DuoSection adminForm>
					<div>
						<h1>New Event</h1>
					</div>
					<div className="admin-form__wrap">
						<div className="admin-form admin-form--event">
							<div className="admin-form__form">
								<form onSubmit={this.handleSubmit} encType="multipart/form-data">
									<div className="admin-form__field-group">
										<h2>Event</h2>
										<AdminEventViewRow
											label="Event Name"
											value={<InputFactory
												type="text"
												name="name"
												scope="event"
												value={inputValues?.event?.name}
												error={inputErrors?.event?.name}
												onChange={this.handleInputChange}
											/>} />
										<AdminEventViewRow
											label="Artist"
											value={<InputFactory
												type="select"
												name="artist_id"
												scope="event"
												value={inputValues?.event?.artist_id}
												error={inputErrors?.event?.artist_id}
												onChange={this.handleInputChange}
												noOptionsMessage={() => "Start typing to find an existing artist..."}
												loadOptions={(inputValue) => this.debouncedLoadArtistOptions(inputValue)}
											/>} />
										<AdminEventViewRow
											label="Venue"
											value={<InputFactory
												type="select"
												name="venue_id"
												scope="event"
												value={inputValues?.event?.venue_id}
												error={inputErrors?.event?.venue_id}
												onChange={this.handleInputChange}
												options={venueOptions}
											/>} />
										<AdminEventViewRow
											label="Event Date"
											value={<InputFactory
												type="date"
												name="starts_date"
												scope="event"
												value={inputValues?.event?.starts_date}
												error={inputErrors?.event?.starts_date}
												onChange={this.handleInputChange}
											/>} />
										<AdminEventViewRow
											label="Event Type"
											value={<InputFactory
												type="select"
												name="type_id"
												scope="event"
												value={inputValues?.event?.type_id}
												error={inputErrors?.event?.type_id}
												onChange={this.handleInputChange}
												options={eventTypeOptions}
											/>} />
										<AdminEventViewRow
											label="Tags"
											value={<InputFactory
												type="select-tags"
												name="tags"
												scope="event"
												value={inputValues?.event?.tags}
												error={inputErrors?.event?.tags}
												onChange={this.handleInputChange}
												isMulti
												noOptionsMessage={() => "Start typing to find or create a tag ..."}
												loadOptions={(inputValue) => this.debouncedLoadTagOptions(inputValue)}
											/>} />
										<AdminEventViewRow
											label="Publish Date"
											value={<InputFactory
												type="date"
												name="publish_at_date"
												scope="event"
												value={inputValues?.event?.publish_at_date}
												error={inputErrors?.event?.publish_at_date}
												onChange={this.handleInputChange}
											/>} />
										<AdminEventViewRow
											label="Publish Time"
											value={<InputFactory
												type="time"
												name="publish_at_time"
												scope="event"
												value={inputValues?.event?.publish_at_time}
												error={inputErrors?.event?.publish_at_time}
												onChange={this.handleInputChange}
											/>} />
										<AdminEventViewRow
											label="Closing Date"
											value={<InputFactory
												type="date"
												name="application_deadline"
												scope="event"
												value={inputValues?.event?.application_deadline}
												error={inputErrors?.event?.application_deadline}
												onChange={this.handleInputChange}
											/>} />
									</div>
									<div className="admin-form__field-group">
										<h2>Start Date and Time</h2>
										<AdminEventViewRow
											label="Door Times"
											value={<InputFactory
												type="time"
												name="door_times_time"
												scope="event"
												value={inputValues?.event?.door_times_time}
												error={inputErrors?.event?.door_times_time}
												onChange={this.handleInputChange}
											/>} />
										<AdminEventViewRow
											label="Event Starts"
											value={<InputFactory
												type="time"
												name="starts_time"
												scope="event"
												value={inputValues?.event?.starts_time}
												error={inputErrors?.event?.starts_time}
												onChange={this.handleInputChange}
											/>} />
									</div>
									<div className="admin-form__field-group">
										<h2>Tickets</h2>
										<AdminEventViewRow
											label="Tickets Available"
											value={<InputFactory
												type="number"
												name="tickets_available"
												scope="event"
												step="1"
												min="0"
												value={inputValues?.event?.tickets_available}
												error={inputErrors?.event?.tickets_available}
												onChange={this.handleInputChange}
											/>} />
										<AdminEventViewRow
											label="Max per Transaction"
											value={<InputFactory
												type="number"
												name="max_tickets_per_transaction"
												scope="event"
												step="1"
												min="0"
												value={inputValues?.event?.max_tickets_per_transaction}
												error={inputErrors?.event?.max_tickets_per_transaction}
												onChange={this.handleInputChange}
											/>} />
										<AdminEventViewRow
											label="Face Value/1 Ticket (£)"
											value={<InputFactory
												type="number"
												name="ticket_face_value"
												scope="event"
												step="0.01"
												min="0.00"
												value={inputValues?.event?.ticket_face_value}
												error={inputErrors?.event?.ticket_face_value}
												onChange={this.handleInputChange}

											/>} />
										<AdminEventViewRow
											label="Email Content: Claim Tickets"
											value={<InputFactory
												type="tinymce"
												name="email_content_winner_accepts"
												scope="event"
												initialValue={initialValues?.event?.email_content_winner_accepts}
												value={inputValues?.event?.email_content_winner_accepts}
												error={inputErrors?.event?.email_content_winner_accepts}
												onChange={this.handleInputChange}
												config="ballot-winners"
											/>} />
									</div>
									<div className="admin-form__field-group">
										<h2>Geolock</h2>
										<AdminEventViewRow
											label="Geo-Locked?"
											value={<InputFactory
												type="select"
												name="geo_locked"
												scope="event"
												empty={false}
												value={inputValues?.event?.geo_locked}
												error={inputErrors?.event?.geo_locked}
												onChange={this.handleInputChange}
												options={[
													{
														value: 0,
														label: 'No',
													},
													{
														value: 1,
														label: 'Yes',
													},
												]}
											/>} />
										<AdminEventViewRow
											disabled={inputValues['event']['geo_locked']['value'] !== 1}
											label="Geo-Lock Radius (miles)"
											value={<InputFactory
												type="number"
												name="geo_lock_radius"
												scope="event"
												step="1"
												value={inputValues?.event?.geo_lock_radius}
												error={inputErrors?.event?.geo_lock_radius}
												onChange={this.handleInputChange}
												disabled={inputValues['event']['geo_locked']['value'] !== 1}
											/>} />
									</div>
									<div className="admin-form__field-group">
										<h2>Images and Description</h2>
										<AdminEventViewRow
											label="Synopsis"
											value={<InputFactory
												type="textarea"
												name="synopsis"
												scope="event"
												value={inputValues?.event?.synopsis}
												error={inputErrors?.event?.synopsis}
												onChange={this.handleInputChange}
											/>} />
										<AdminEventViewRow
											label="Tour Logo"
											value={<InputFactory
												type="file"
												name="logo"
												scope="event"
												value={inputValues?.event?.logo}
												initialFilePreview={inputValues?.event?.logoPreview || null}
												error={inputErrors?.event?.logo}
												onChange={this.handleFileChange}
											/>} />
										<AdminEventViewRow
											label="Feature Image"
											value={<InputFactory
												type="file"
												name="image"
												scope="event"
												value={inputValues?.event?.image}
												initialFilePreview={inputValues?.event?.imagePreview || null}
												error={inputErrors?.event?.image}
												onChange={this.handleFileChange}
											/>} />
									</div>

									<div className="admin-form__submit-wrap">
										<Button type="button" to="/admin/events" colorEndeavour hollow>Cancel</Button>
										<Button type="submit" isLoading={isSubmitting} colorEndeavour>Add Event</Button>
									</div>
								</form>
							</div>
						</div>
					</div>
				</DuoSection>
			</LayoutAdmin>
		</PrivateRoute>
	}

	componentDidMount() {
		this.fetchEventTypeOptions()
		this.fetchVenueOptions()
		this.handleDuplicate()
		this.handleVenueId()
	}

	async fetchEventTypeOptions() {
		const eventTypeService = new EventTypeService()

		const eventTypeResponse = await eventTypeService.get()
		let eventTypeOptions = []
		eventTypeResponse.data.forEach((eventTypeCategory) =>  {
			let options = []
			eventTypeCategory.types.forEach((eventType) =>  {
				options.push({
					label: eventType?.name,
					value: eventType?.id,
				})
			})
			eventTypeOptions.push({
				label: eventTypeCategory?.name,
				options: options
			})
		})
		this.setState({
			eventTypeOptions: eventTypeOptions
		})
	}

	async fetchVenueOptions() {
		const venueService = new VenueService()

		const venueResponse = await venueService.list()
		let venueOptions = []
		venueResponse.data.map(function(venue)  {
			venueOptions.push({
				label: venue?.name,
				value: venue?.id,
			})
			return venueOptions
		})
		this.setState({
			venueOptions: venueOptions,
			venues: venueResponse.data
		})
	}

	handleDuplicate = async () => {
		const {search} = this.props
		if (search.duplicate) {
			const apiService = new ApiService()
			await apiService.get(`events/${search.duplicate}`)
				.then(async (eventResponse) => {
					const {initialValues, inputValues} = this.state
					const publish_at = moment(eventResponse.data.publish_at)
					const application_deadline = moment(eventResponse.data.application_deadline)
					const door_times = moment(eventResponse.data.door_times)
					const starts = moment(eventResponse.data.starts)

					inputValues.event.name = eventResponse.data.name
					inputValues.event.artist_id = {label: eventResponse.data.artist.name, value: eventResponse.data.artist.id}
					inputValues.event.type_id = {label: eventResponse.data.type.name, value: eventResponse.data.type.id}
					inputValues.event.publish_at_date = publish_at.format('YYYY-MM-DD')
					inputValues.event.publish_at_time = publish_at.format('HH:mm')
					inputValues.event.application_deadline = application_deadline.format('YYYY-MM-DD')

					inputValues.event.venue_id = {label: eventResponse.data.venue.name, value: eventResponse.data.venue.id}
					inputValues.event.starts_date = starts.format('YYYY-MM-DD')
					inputValues.event.door_times_time = door_times.format('HH:mm')
					inputValues.event.starts_time = starts.format('HH:mm')

					inputValues.event.tickets_available = eventResponse.data.tickets_available
					inputValues.event.max_tickets_per_transaction = eventResponse.data.max_tickets_per_transaction
					inputValues.event.ticket_face_value = eventResponse.data.ticket_face_value
					if (eventResponse.data.email_content_winner_accepts) {
						inputValues.event.email_content_winner_accepts = eventResponse.data.email_content_winner_accepts
						initialValues.event.email_content_winner_accepts = eventResponse.data.email_content_winner_accepts
					}

					inputValues.event.geo_locked = {
						label: 'No',
						value: 0
					}
					
					inputValues.event.geo_lock_radius = eventResponse.data.geo_lock_radius

					inputValues.event.synopsis = eventResponse.data.synopsis

					await fetch(eventResponse.data.logo)
						.then(res => res.blob())
						.then(blob => {
							inputValues.event.logo = new File([blob], 'logo', {type: blob.type})
							inputValues.event.logoPreview = eventResponse.data.logo
						})
					await fetch(eventResponse.data.image)
						.then(res => res.blob())
						.then(blob => {
							inputValues.event.image = new File([blob], 'image', {type: blob.type})
							inputValues.event.imagePreview = eventResponse.data.image
						})

					this.setState({inputValues: inputValues})
				})
				.catch(err => console.error(err))
		}
	}

	handleVenueId = () => {
		const {search} = this.props
		if (search.venueId) {
			const {inputValues} = this.state
			inputValues['event']['venue_id'] = search.venueId
		}
	}

	loadTagOptions = (inputValue) => {
		if (!inputValue) {
			return []
		}

		const tagService = new TagService()
		return tagService.getOptions(inputValue)
			.then((response) => {
				if (response.success) {
					return response.data
				}
				else {
					const {addNotification} = this.context
					addNotification('Tag search failed', 'error')
					return []
				}
			}).catch(() => {
				const {addNotification} = this.context
				addNotification('Tag search failed', 'error')
			})
	}

	loadArtistOptions = (inputValue) => {
		if (!inputValue) {
			return []
		}

		const artistService = new ArtistService()
		return artistService.getOptions(inputValue)
			.then((response) => {
				if (response.success) {
					return response.data
				}
				else {
					const {addNotification} = this.context
					addNotification('Artist search failed', 'error')
					return []
				}
			}).catch(() => {
				const {addNotification} = this.context
				addNotification('Artist search failed', 'error')
			})
  }
  
	updateDefaultTimesAndTicketsAvailable(venueId) {
		const {inputValues, venues} = this.state
		const venue = venues.find((venue) => venue.id === venueId)

		const defaultStartTime = venue.default_starts_time && moment(venue.default_starts_time).format('HH:mm')
		const defaultDoorTime = venue.default_door_times && moment(venue.default_door_times).format('HH:mm')
		const defaultMaxTicketsPerTransaction = venue.default_max_tickets_per_transaction

		if (defaultStartTime !== null) {
			inputValues.event.starts_time = defaultStartTime
		}

		if (defaultDoorTime !== null) {
			inputValues.event.door_times_time = defaultDoorTime
		}

		if (defaultMaxTicketsPerTransaction !== null) {
			inputValues.event.max_tickets_per_transaction = defaultMaxTicketsPerTransaction
		}
	}

	updateApplicationDeadline(startsDate) {
		const {inputValues} = this.state
		inputValues.event.application_deadline = moment(startsDate).subtract(14, 'days').format('YYYY-MM-DD')
	}

	handleInputChange = (value, name, scope) => {
		// Get current form data from state
		const {inputValues} = this.state
		const venueHasChanged = name === "venue_id"
		const startsDateHasChanged = name === "starts_date"

		if (venueHasChanged) {
			this.updateDefaultTimesAndTicketsAvailable(value.value)
		}

		if (startsDateHasChanged) {
			this.updateApplicationDeadline(value)
		}

		if (value !== undefined) {
			// Update field value
			if (scope) {
				inputValues[scope][name] = value
			}
			else {
				inputValues[name] = value
			}
		} else {
			// Remove field value
			if (scope) {
				delete inputValues[scope][name]
			}
			else {
				delete inputValues[name]
			}
		}

		// Update state of file input values
		this.setState({ inputValues })
	}

	handleFileChange = (value, name, scope) => {
		// Get current form data from state
		const {inputValues} = this.state

		if (scope) {
			inputValues[scope][name] = value
		}
		else {
			inputValues[name] = value
		}

		// Update state of file input values
		this.setState({ inputValues })
	}

	handleSubmit = async (event) => {
		event.preventDefault()
		this.setState({
			isSubmitting: true
		})

		const {inputValues, inputErrors} = this.state
		const eventService = new EventService()
		const {addNotification} = this.context

		// submit event data
		const formData = eventService.formData(inputValues)
		await eventService.post(formData)
			.then(async (eventResponse) => {
				if (eventResponse.success) {
					addNotification('New Event Created', 'success')
					this.props.navigate('/admin/events')
				}
				else {
					inputErrors['event'] = eventResponse.errors
					this.setState({
						inputErrors: inputErrors,
						isSubmitting: false
					})
				}
			})
			.catch(err => console.error(err))
	}
}

export default withLocation(AdminEventsNewPage)
